const COUNT_ON_PAGE = 10;

class VaultService {
    static get $inject() {
        return ['aerosAdminApi']
    }

    constructor(aerosAdminApi) {
        this.aerosAdminApi = aerosAdminApi;
    }

    getItems(params, filterFields) {
        return this.aerosAdminApi.vaultApi.filter({
            "count": COUNT_ON_PAGE,
            ...params,
            filterFields
        });
    }
}

{
    angular.module('adminApp')
        .service('VaultService', VaultService);
}
